<template>
  <el-container class="xa-container">
    <el-aside width="240px">
      <AppAsideMenu :nav="nav" @change="onChangeNav" />
    </el-aside>
    <el-container>
      <el-header class="xa-cell" height="40px">
        <div>{{ page.title }}</div>
      </el-header>
      <el-main style="padding:0 8px">
        <XaForm
          v-if="items.length"
          :items="items"
          :values="variables"
          @finish="handleFinish"
        />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
const dataBase = () => import('@/test/index')
async function requireData(path) {
  const content = await dataBase()
  return content.default(path)
}
async function requireKeys() {
  const content = await dataBase()
  return content.keys
}
import AppAsideMenu from '@/components/AppAsideMenu'
import recodeLastPath from '@/controllers/recodeLastPath'
export default {
  components: {
    AppAsideMenu,
    XaForm: () => import('@/components/xa-form/form/index')
  },
  data() {
    return {
      isLoading: true,
      nav: [],
      page: {
        title: '表单'
      },
      parent: {
        // 上一个页面得到信息
        path: '',
        title: ''
      },
      items: [],
      variables: {}
    }
  },
  watch: {
    $route() {
      this.items = []
      this.fetchData()
    }
  },
  methods: {
    onChangeNav(item) {
      window.console.log(item)
      this.$router.replace({
        path: '/diyForm?src=' + item
      })
    },
    async fetchData() {
      const data = await requireData(this.$route.query.src || 'diyForm_all')
      this.page.title = data.doc_title || this.page.title
      const { items, variables } = data
      this.items = items
      this.variables = variables
      this.isLoading = false
    },
    handleFinish({ cfg, result, value }) {
      setTimeout(() => {
        if (result.href || result.url) {
          this.$gotoUrl(result.href || result.url)
        } else if (cfg.success && cfg.success.src) {
          let url = cfg.success.src
          if (cfg.success.params) {
            url = this.$utils.appendUrl(
              url,
              this.$utils.buildParamsUrl(cfg.success.params, value)
            )
          }
          this.$gotoUrl(url)
        } else if (this.parent.path) {
          this.$router.replace(this.parent.path)
        }
      }, 500)
    }
  },
  beforeRouteEnter(to, from, next) {
    /* 如果是导航点击的跳转地址 着直接打开 */
    if (to.query.nav) {
      next()
    } else {
      next(vm => {
        const parent = recodeLastPath(from, vm, 'DIYFORM_PARENT')
        if (parent) vm.parent = parent
      })
    }
  },
  mounted() {
    this.fetchData()
    requireKeys().then(keys => {
      this.nav = keys.map(item => {
        return {
          title: item,
          key: item,
          index: item
        }
      })
    })
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-aside {
  background-color: rgb(31, 45, 61);
  .el-menu {
    border-right: none;
  }
}
</style>
